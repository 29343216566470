import {Link} from 'react-router-dom'

import diner from './images/diner.jpg'
import movies from './images/projector-min.jpg'
import quiz from './images/quiz-min.jpg'
import cage from './images/cage.jpg'
import crypto from './images/crypto/crypto.jpg'
import seCasual from './images/se-casual.jpg'

const Projects = () =>{
    document.title = "Projects"
    return(
        <>
            <div id="project-box">
                <h1>Projects</h1>
            </div>
            
            <section id='project-code'>
                <div className="project-card">
                    <Link to="/restaurant"><img src={diner} alt="diner" width="500px"></img></Link>
                    <h1 id="project-des">Restaurant</h1>
                </div>
                <div>
                    <a href="https://github.com/danh-22/portfolio/blob/main/Restaurant.js"><h1 style={{textDecoration: "underline"}}>Source code</h1></a>
                </div>
                </section>

<section id='project-code'>
                <div className="project-card">
                    <Link to="/movies"><img src={movies} alt="movies" width="500px"></img></Link>
                    <h1 id="project-des">Movie Search</h1>
                </div>
                <div>
                    <a href="https://github.com/danh-22/portfolio/blob/main/Movies.js"><h1 style={{textDecoration: "underline"}}>Source code</h1></a>
                </div>
                </section>

<section id='project-code'>
                <div className="project-card">
                  <Link to="/quiz"><img src={quiz} alt="quiz" width="500px"></img></Link>
                    <h1 id="project-des">Quiz</h1>
                </div>
                <div>
                   <a href="https://github.com/danh-22/portfolio/tree/main/quiz"><h1 style={{textDecoration: "underline"}}>Source code</h1></a> 
                </div>
                </section>

<section id='project-code'>
                <div className="project-card">
                  <a href="https://mmacentral.co.uk/"><img src={cage} alt="cage" width="500px"></img></a>
                    <h1 id="project-des">MMA Central</h1>
                </div>
                <div>
                   <a href="https://github.com/danh-22/portfolio/tree/main/"><h1 style={{textDecoration: "underline"}}>Source code</h1></a> 
                </div>
                </section>
<section id='project-code'>
                <div className="project-card">
                  <Link to="/crypto"><img src={crypto} alt="cage" width="500px"></img></Link>
                    <h1 id="project-des">Crypto Info</h1>
                </div>
                <div>
                   <a href="https://github.com/danh-22/portfolio/tree/main/"><h1 style={{textDecoration: "underline"}}>Source code</h1></a> 
                </div>
            </section>
            <section id='project-code'>
                <div className="project-card">
                  <a href="https://nextjs-swart-five-78.vercel.app/"><img src={seCasual} alt="cage" width="500px"></img></a>
                    <h1 id="project-des">E-Commerce</h1>
                </div>
                <div>
                   <a href="https://github.com/danh-22/nextjs"><h1 style={{textDecoration: "underline"}}>Source code</h1></a> 
                </div>
            </section>
        </>
    )
}

export default Projects