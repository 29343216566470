import { useState } from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
    const [display, setDisplay] = useState(false)

    return (
        <section className='navbar'>
            <div>
                <h1>Daniel Hockey Portfolio</h1>
            </div>

            <div id="nav-links">
                <Link to="/">Home</Link>
                <div>
                    <Link to='/projects'>Projects</Link>
                    {display ? <i onClick={() => setDisplay(false)} className="bi bi-caret-up-fill"></i>
                        : <i onMouseEnter={() => setDisplay(true)} onClick={() => setDisplay(true)} className="bi bi-caret-down-fill"></i>}
                    {display &&
                        <div className="dropdown" onMouseLeave={() => setDisplay(false)}>
                            <Link to='./restaurant'>Restaurant</Link>
                            <Link to='./movies'>Movie Search</Link>
                            <Link to='./quiz'>Quiz</Link>
                            <Link to='./crypto'>Crypto</Link>
                            <a href='https://nextjs-swart-five-78.vercel.app/'>E-Commerce</a>
                        </div>}
                </div>
                <Link to="/contact">Contact</Link>
            </div>


        </section>

    )
}

export default Navbar