import {useState} from 'react'

import burger from './images/restaurant/food-text.jpg'
import open from './images/restaurant/open-2.jpg'
import logo from './images/restaurant/dans-diner.png'

import crypto from './images/restaurant/crypto.jpg'
import Menu from './data/Menu'

const Restaurant = () => {
document.title = "Restaurant"
const images = [open, burger, crypto]
let [imageNumber, setImageNumber] = useState(0)

function increase(){
    setImageNumber(++imageNumber)
    if(imageNumber > images.length -1){
        setImageNumber(0) 
    }
}

function decrease(){
    setImageNumber(--imageNumber)
    if(imageNumber === -1){
        setImageNumber(2)
    }
}

return(
    <section className="restaurant">
    <div className="diner-sidebar text-center">
    <div id="sticky">
    <img onClick={()=> window.scrollTo(0, 0)} src={logo} alt="dans-diner" width="300px"></img>
    <h1 onClick={()=> window.innerWidth < 600 ? window.scrollTo(0, 600) :  window.scrollTo(0, 850)}>Menu</h1>
    <h1 onClick={()=>  window.innerWidth < 600 ? window.scrollTo(0, 1800) : window.scrollTo(0, 1650)}>About Us</h1>
    <h1 onClick={()=>  window.innerWidth < 600 ? window.scrollTo(0, 3000) :  window.scrollTo(0, 2400)}>Location</h1>
    </div>
    </div>
    
    <div id="diner-content">
    <div className="diner-imgs">
    <img src={images[imageNumber]} alt="diner" width="100%" height="800px"></img>
    <i onClick={decrease} id="arrow-left" className="bi bi-caret-left-fill"></i>
    <i onClick={increase} id="arrow-right" className="bi bi-caret-right-fill"></i>       
    </div>

    <Menu/>

    {/*About Us*/}
    <div className="about-us">
        <h1 className="restaurant-heading text-center"> About Us</h1>
        <div id="about-text">
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

        Why do we use it?
        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved </p>

        <h2><span style={{textDecoration: "underline"}}>Opening Times</span>
        <br/>
        Monday: 10am - 11pm <br/> Tuesday: 10am - 11pm <br/>Wednesday 10am - 11pm <br/> Thursday: 10am - 11pm <br/> Friday: 10am - 11pm <br/> Saturday: 10am - 11pm <br/> Sunday: 12pm - 10pm       
        </h2>
        </div>
    </div>
    
    
    {/*Location*/}
    <div className="location">
        <h1 className="restaurant-heading text-center">Where to find us</h1>
        <div id="diner-contact">
        <div className="mapouter"><div className="gmap_canvas"><iframe title="maps" width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=se10%200dx&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe></div></div>

        <div>
        <h2>Inside the O2, SE10 0DX <br/> <a href="https://www.theo2.co.uk/visit-us/getting-to-the-o2">Guide for transport</a></h2>
        <h2><span>Contact Details</span><br/>Telephone: 0203 367 8655<br/> Email: contact@dansdiner.co.uk</h2>
        </div>
        </div>
    </div>

    </div>
    

    </section>
    
)
}

export default Restaurant