import {useState, useEffect} from 'react'
import ada from './images/crypto/ada-logo.png'
import bitcoin from './images/crypto/btc-logo.png'
import bch from './images/crypto/bch-logo.png'
import eth from './images/crypto/eth-logo.png'
import ltc from './images/crypto/ltc-logo.png'

const Crypto = () => {

    const [data, setData] = useState(null)
    const [cryptoData, setCryptoData] = useState([])
    document.title = "Crypto Converter"
    
    useEffect(()=> {
    fetch("https://api.coindesk.com/v1/bpi/currentprice.json")
    .then((response)=> {
       return  response.json()
    })
    .then((result)=> {
        return setData(result)
    })
    }, [])

    useEffect(()=> {
        fetch("https://api2.binance.com/api/v3/ticker/24hr")
        .then((response)=> {
            return response.json()
        })
        .then((result)=> {
            return setCryptoData(result)
        })
    }, [])


    const [fiat, setFiat] = useState("")
    const [btc, setBtc] = useState("")
    

    function fiatFunction(e){
        if(data){
        let number = data.bpi.USD.rate
        let newNumber = Number(number.replace(",", ""))

        setFiat(e.target.value)
        setBtc((e.target.value / newNumber).toFixed(4))
    }
    }

    
    function btcFunction(e){
        if(data){
            let number = data.bpi.USD.rate
            let newNumber = Number(number.replace(",", ""))
            setBtc(e.target.value)
            setFiat((e.target.value * newNumber).toFixed(2))
            
        }
    }

    function clearInputs(){
        setBtc("")
        setFiat("")
    }

    return(
        <section className="crypto">
        <section id="crypto-header">
            <div id="sign-up">
                <h1>Buy, Trade and Hold Crypto</h1>
                <input type="text" placeholder="email"></input>
                <br/>
                <button>Sign Up</button>
            </div>

            <div id="converter" onClick={clearInputs}>
            <h1>Crypto Converter</h1>
            
            <label>USD</label>
            <input value={fiat} onChange={fiatFunction}/>
            <br/>
            <label>BTC</label>
            <input value={btc} onChange={btcFunction}/>
            <br/>
            <button>Buy Now</button>            
        </div>
            
        </section>

        <section className="slides">
            <img src={bitcoin} alt="bitcoin"></img>
            <img src={eth} alt="ethereum"></img>
            <img src={ada} alt="cardano"></img>
            <img src={bch} alt="bitcoin cash"></img>
            <img src={ltc} alt="litecoin"></img>
        </section>


        <section id="crypto-data">
        <h1>Live Crypto Rates</h1>
        {cryptoData && 
        <table>
            <thead>
                <tr>
                <th>Coin Name</th>
                <th>Coin Price $</th>
                <th>Price Change 24hr</th>
            </tr>


            </thead>
            
            {cryptoData.map((data, index)=> {
                    return(
                    data.symbol.includes("BTCUSDT") &&                        
                    <tbody key={index}>
                    <tr key={index}>
                    <td>{data.symbol}</td>
                    <td>{Number(data.askPrice).toFixed(2)}</td>
                    <td className={data.priceChangePercent < 0 ? "minus" : "plus"}>{data.priceChangePercent}%</td>
                    </tr>
                    </tbody>
                    )
            })}

            {cryptoData.map((data, index)=> {
                    return(
                    data.symbol.includes("ETHUSDT") &&                        
                    <tbody key={index}>
                    <tr key={index}>
                    <td>{data.symbol}</td>
                    <td>{Number(data.askPrice).toFixed(2)}</td>
                    <td className={data.priceChangePercent < 0 ? "minus" : "plus"}>{data.priceChangePercent}%</td>
                    </tr>
                    </tbody>
                    )
            })}

            {cryptoData.map((data, index)=> {
                    return(
                    data.symbol.includes("ADAUSDT") &&                        
                    <tbody key={index}>
                    <tr key={index}>
                    <td>{data.symbol}</td>
                    <td>{Number(data.askPrice).toFixed(2)}</td>
                    <td className={data.priceChangePercent < 0 ? "minus" : "plus"}>{data.priceChangePercent}%</td>
                    </tr>
                    </tbody>
                    )
            })}

            {cryptoData.map((data, index)=> {
                    return(
                    data.symbol.includes("LTCUSDT") &&                        
                    <tbody key={index}>
                    <tr>
                    <td>{data.symbol}</td>
                    <td>{Number(data.askPrice).toFixed(2)}</td>
                    <td className={data.priceChangePercent < 0 ? "minus" : "plus"}>{data.priceChangePercent}%</td>
                    </tr>
                    </tbody>
                    )
            })}

            {cryptoData.map((data, index)=> {
                    return(
                    data.symbol.includes("BCHUSDT") &&                        
                    <tbody key={index}>
                    <tr>
                    <td key={index}>{data.symbol}</td>
                    <td>{Number(data.askPrice).toFixed(2)}</td>
                    <td className={data.priceChangePercent < 0 ? "minus" : "plus"}>{data.priceChangePercent}%</td>
                    </tr>
                    </tbody>
                    )
            })}

           

        </table>
        }

        
        </section>
        </section>
    )
}

export default Crypto